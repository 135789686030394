/**
 * @author zgl
 * @description
 */

import { RouteRecordRaw } from "vue-router";

const HomeTab = () => import("./HomeTab.vue");
// const Home = () => import("./Home.vue");
import Home from "./Home.vue";
import About from "./About.vue";
import Me from "./Me.vue";
import Faq from "./Faq.vue";

const basePath = "/homeTab/";
const baseName = "HomeTab";
export const RoutePath = {
  homeTab: basePath,
  home: basePath + "home",
  about: basePath + "about",
  me: basePath + "me",
  faq: basePath + "faq",
};

export const RouteName = {
  homeTab: baseName,
  home: baseName + "Home",
  about: basePath + "About",
  me: basePath + "Me",
  faq: basePath + "Faq",
};

export const Route: Array<RouteRecordRaw> = [
  {
    path: RoutePath.homeTab,
    name: RouteName.homeTab,
    component: HomeTab,
    children: [
      {
        path: RoutePath.home,
        name: RouteName.home,
        component: Home,
      },
      {
        path: RoutePath.about,
        name: RouteName.about,
        component: About,
      },
      {
        path: RoutePath.me,
        name: RouteName.me,
        component: Me,
      },
      {
        path: RoutePath.faq,
        name: RouteName.faq,
        component: Faq,
      },
    ],
  },
];
