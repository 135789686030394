/**
 * author:zg
 */

import BtMb from "./BtMb.vue";
import MbTitle from "./MbTitle.vue";

export const MyMbComponent: any = {
  BtMb,
  MbTitle,
}
