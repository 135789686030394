/**
 * Created by zgl
 * @description:
 */

import MyInputVue from "./MyInput.vue";
import MyTitleVue from "./MyTitle.vue";
import MyFileUploader from "./MyUploadFile.vue";
import Bt from "./Bt.vue";

export const MyComponent: any = {
  MyTitle: MyTitleVue,
  MyInput: MyInputVue,
  MyFileUploader,
  Bt,
}
