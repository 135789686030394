import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@icon/back.png'


const _withScopeId = n => (_pushScopeId("data-v-28e55d9e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "MyTitle" }
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  alt: "",
  class: "iconBack"
}
const _hoisted_3 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = () => !$props.noBack && _ctx.$router.back()),
      class: "left"
    }, [
      (!$props.noBack)
        ? (_openBlock(), _createElementBlock("img", _hoisted_2))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString($props.title || _ctx.$route.meta.title), 1),
    _createElementVNode("div", {
      onClick: _cache[1] || (_cache[1] = () => $props.tapRight && $props.tapRight()),
      class: "right"
    }, [
      _createElementVNode("span", null, _toDisplayString($props.right), 1)
    ])
  ]))
}