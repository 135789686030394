/**
 * @author zgl
 */

export const MyImage = {
  logo: require("./vite.svg"),
  rightArrow: require("./ra.svg"),
  home: {
    logo: require("./home/logo.png"),
    // sec1: require("./home/sec1.png"),
    // sec1Bg: require("./home/sec1Bg.png"),
    // sec1Star: require("./home/sec1Star.png"),
    // lan: require("./home/lanBeta.svg"),
    // our: require("./home/our.png"),
    // high: require("./home/high.png"),
    // high1: require("./home/high1.png"),
    // high2: require("./home/high2.png"),
    // high3: require("./home/high3.png"),
    // star: require("./home/star.png"),
    // twiter: require("./home/twiter.png"),
    // tw1: require("./home/tw1.png"),
    // tw2: require("./home/tw2.png"),
    sec1Bg: require("./home/bg1.svg"),
    sBg: require("./home/chipmunk.svg"),
    the: require("./home/the.svg"),
    sec: require("./home/sec.svg"),
    s2Bg1: require("./home/s2Bg1.svg"),
    s2Bg2: require("./home/s2Bg2.svg"),
    lender1: require("./home/lender1.svg"),
    lenderPs: require("./home/lenderPs.svg"),
    lenderBd: require("./home/lenderBd.svg"),
    lenderOt: require("./home/lenderOt.svg"),
    azuki: require("./home/azuki.svg"),
    bayc: require("./home/bayc.svg"),
    bor1: require("./home/bor1.svg"),
    bor2: require("./home/bor2.svg"),
    maxIntr: require("./home/maxIntr.svg"),
    ld1: require("./home/ld1.svg"),
    ld2: require("./home/ld2.svg"),
    ld3: require("./home/ld3.svg"),
    ld4: require("./home/ld4.svg"),
    ldBg: require("./home/ldBg.svg"),
    br1: require("./home/br1.svg"),
    br2: require("./home/br2.svg"),
    br3: require("./home/br3.svg"),
    br4: require("./home/br4.svg"),
    br5: require("./home/br5.svg"),
    road1: require("./home/road1.svg"),
    road2: require("./home/road2.svg"),
    road3: require("./home/road3.svg"),
    road4: require("./home/road4.svg"),
    roadBg: require("./home/roadBg.svg"),
    off: require("./home/off.svg"),
    on: require("./home/on.svg"),
    faqBg1: require("./home/faqBg1.svg"),
    faqBg2: require("./home/faqBg2.svg"),
    sw1: require("./home/sw1.svg"),
    sw2: require("./home/sw2.svg"),
    sw3: require("./home/sw3.svg"),
    sw4: require("./home/sw4.svg"),
    sw5: require("./home/sw5.svg"),
    sw6: require("./home/sw6.svg"),
    sw7: require("./home/sw7.svg"),
    sw8: require("./home/sw8.svg"),
    sw9: require("./home/sw9.svg"),
    sw10: require("./home/sw10.svg"),
    sw11: require("./home/sw11.svg"),
    sw12: require("./home/sw12.svg"),
    sw13: require("./home/sw13.svg"),
    sw14: require("./home/sw14.svg"),
    sw15: require("./home/sw15.svg"),
    sw16: require("./home/sw16.svg"),
    sw17: require("./home/sw17.svg"),
    parts: require("./home/parts.svg"),
    pa: require("./home/pa.svg"),
    ssBg: require("./home/ssBg.svg"),
    shine:require("./home/shine.svg"),
  },
  faq: {
    note: require("./faq/note.png"),
  },
  login: {
    bgTop: require("./login/pwd.png"),
  },
  mbHome: {
    logo: require("./mbHome/logo.svg"),
    drawer: require("./mbHome/drawer.png"),
    x: require("./mbHome/x.svg"),
    max: require("./mbHome/max.svg"),
    rdBg: require("./mbHome/rdBg.svg"),
    q2: require("./mbHome/q2.svg"),
    q4: require("./mbHome/q4.svg"),
    lenderPs: require("./home/lenderPs.svg"),
    sw1: require("./mbHome/sw1.svg"),
    sw2: require("./mbHome/sw2.svg"),
    sw3: require("./mbHome/sw3.svg"),
    sw4: require("./mbHome/sw4.svg"),
    sw5: require("./mbHome/sw5.svg"),
    sw6: require("./mbHome/sw6.svg"),
    sw7: require("./mbHome/sw7.svg"),
    sw8: require("./mbHome/sw8.svg"),
    sw9: require("./mbHome/sw9.svg"),
    sw10: require("./mbHome/sw10.svg"),
    sw11: require("./mbHome/sw11.svg"),
    sw12: require("./mbHome/sw12.svg"),
    sw13: require("./mbHome/sw13.svg"),
    sw14: require("./mbHome/sw14.svg"),
    sw15: require("./mbHome/sw15.svg"),
    sw16: require("./mbHome/sw16.svg"),
    sw17: require("./mbHome/sw17.svg"),
    rd1: require("./mbHome/rd1.svg"),
    rd2: require("./mbHome/rd2.svg"),
    rd3: require("./mbHome/rd3.svg"),
    rd4: require("./mbHome/rd4.svg"),
  },
};
