
export default {
  name: "MyTitle",
  props: {
    noBack: Boolean,
    title: String,
    right: String,
    tapRight: Function,
  }
}
