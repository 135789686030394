/**
 * @author zgl
 */

import { service } from "@/util/Net";
import { net } from "@util";

/**
 * @description 上传文件
 */
export const postUploadFile = (formdata: FormData) => {
  return service.post("file/upload", formdata, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

/**
 * @description:获取报告
 */
export const postReport = (data: { file_url: "" }) => {
  return net({
    url: "krt/get-krt",
    data,
  });
};

/**
 * @description:获取报告
 */
export const postSave = (data: { ekrt: any }) => {
  return net({
    url: "krt/save-krt",
    data,
  });
};

/**
 * @description:获取报告
 */
export const postSaveEpq = (data: { ekrt: any }) => {
  return net({
    url: "epq/save-epq",
    data,
  });
};

interface eEPQ {
  id?: number;
  proposal_url: string;
  paper_url: string;
  type: string;
  epq_result?: string;
}

export const postEpq = (data:{ eepq: eEPQ }) => {
  return net({
    url: "epq/get-epq",
    data,
  });
};
