
import { InputHTMLAttributes, PropType } from 'vue';

interface Ipt extends InputHTMLAttributes {
  label: string;
  icon: any;
  rightIcon: string;
  rightText: string;
  tapRight: Function;
}
export default {
  name: "MyInput",
  props: {
    ipt: {
      type: Object as PropType<Ipt>,
      default: {},
    }
  },
}
