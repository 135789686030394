/**
 * Created by zgl
 * @description:
 */

import LoginVue from "./Login.vue"

export const RoutePath = {
  login: "/login",
}
export const RouteName = {
  login: "Login",
}
export const LoginRoute = [
  {
    path: RoutePath.login,
    name: RouteName.login,
    component: LoginVue,
    meta: {
      title: "登录"
    }
  }
]