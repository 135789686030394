/**
 * Created by zgl
 */

import { ElMessageBox } from "element-plus";

export const formatTime = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  return `${[year, month, day].map(formatNumber).join("/")} ${[
    hour,
    minute,
    second,
  ]
    .map(formatNumber)
    .join(":")}`;
};

export const formatNumber = (n: string | number) => {
  n = n.toString();
  return n[1] ? n : `0${n}`;
};

export const log = (...arg: any[]) => {
  if (window.debug) {
    console.log(...arg);
  }
};

export const todayDate = () => {
  return formatTime(new Date()).substring(0, 10);
};

export const nowTime = () => {
  return formatTime(new Date()).substring(11);
};

// 手机号正则 phoneRe.test(15678045718) true
export const isPhone = (str: string | undefined) => {
  if (!str) return false;
  const phoneRe = /(^1[3456789][0-9]{9}$)/;
  return phoneRe.test(str);
};

// 两位小数
export const twoPointNum = (num: number | string) => {
  if (!parseFloat(num.toString())) return 0;
  let pre = Math.round(parseFloat(num.toString()) * 100) + "";
  if (pre.length < 3) {
    pre = [...Array(3 - pre.length)].map(() => "0").join("") + pre;
  }
  const point = pre.slice(pre.length - 2, pre.length);
  const it = pre.substring(0, pre.length - 2);
  const res = it + "." + point;
  return res;
};

// // 密码加密
// export const encryptPwd = (pwd = '') => {
//   const ey = new JSEncrypt();
//   ey.setPublicKey(publicKey);
//   return ey.encrypt(pwd);
// }

// 密码规则
export const checkPwd = (pwd = "") => {
  if (!pwd || pwd.length < 6) return 0;
  let complexity = 0;
  if (pwd.match(/[a-z]+/)) complexity++;
  if (pwd.match(/[A-Z]+/)) complexity++;
  if (pwd.match(/[0-9]+/)) complexity++;
  if (pwd.match(/[!@#$%^&*]+/)) complexity++;
  return complexity;
};

// 获取url指定参数
export const getUrlParam = (name = "") => {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); // 构造一个含有目标参数的正则表达式对象
  const url = window.location.search;
  console.log("window.location.search==", url);
  let r = url.substring(1).match(reg); // 匹配目标参数
  console.log("url.substring(1).match==", r);
  return r ? decodeURIComponent(r[2]) : "";
};

// 只显示最后一个字
export const showLast = (name = "") => {
  const l = name.length;
  let str = "";
  for (let i = 0; i < l; i++) {
    let s = "*";
    if (i == l - 1) {
      s = name[i];
    }
    str += s;
  }
  return str;
};

// 拼接url
export const opeUrl = (url: string, par: any) => {
  let param = "";
  for (const key in par) {
    if (Object.hasOwnProperty.call(par, key)) {
      const element = par[key];
      if (param) param += "&";
      param += key + "=" + element;
    }
  }
  const u = url + "?" + param;
  console.log("opeUrl==", u);
  return u;
};

//base64 转换 blob
// export const dataURLtoBlob = (dataurl:string) => {
//   const bstr = Buffer.from(dataurl, 'base64');
//   let n = bstr.length;
//   let u8arr = new Uint8Array(n);
//   while (n--) {
//     u8arr[n] = bstr.charCodeAt(n);
//   }
//   return new Blob([u8arr], { type: 'pdf' });
// }

// export const str2File = (data, fileName) => {
//   // res.data   就是后台返回的base64的 文件流
//   const URL = dataURLtoBlob(data);
//   const reader = new FileReader();
//   reader.readAsDataURL(URL);
//   reader.onload = function (e) {
//     // 兼容IE
//     if (window.navigator.msSaveOrOpenBlob) {
//       var bstr = Buffer.from(e.target.result.split(",")[1], 'base64');
//       var n = bstr.length;
//       var u8arr = new Uint8Array(n);
//       while (n--) {
//         u8arr[n] = bstr.charCodeAt(n);
//       }
//       var blob = new Blob([u8arr]);
//       window.navigator.msSaveOrOpenBlob(blob, fileName);
//     } else {
//       // 转换完成，创建一个a标签用于下载
//       var a = document.createElement('a');
//       a.download = fileName;
//       a.href = e.target.result;
//       a.setAttribute("id", "export")
//       // document.getElementById("purchaseManage").append(a); // 修复firefox中无法触发click
//       a.click();
//       // document.getElementById("export").remove();
//     }
//   }
// }

// 简易EventBus
const eventList: any = {};
export const listenerKey = {
  homeTab: "HOMETAB",
  home: "HOME",
};
// 添加事件
export const addListener = (
  key: string,
  listener = { name: "name", fun: (param: any) => {} }
) => {
  const list = eventList[key] || [];
  list.push(listener);
  eventList[key] = list;
};
// 移除事件
export const removeListener = (key: string, name: string) => {
  eventList[key] = (eventList[key] || []).filter((e: any) => e.name != name);
};
// 发送事件
export const sendEvent = (key: string, param = {}) => {
  (eventList[key] || []).forEach(({ fun }: { fun: Function }) => {
    fun(param);
  });
};

/**
 * @description 获取文件后缀
 * @param {fileName} fileName 文件名称
 */
export const getFileType = (fileName: string) => {
  if (!fileName) return "";
  const arr = fileName.split(".");
  const ln = arr.length;
  return arr[ln - 1];
};

/**
 * @description
 * @param {url}
 */
export const getFileName = (url = "") => {
  if (!url) return "";
  const arr = url.split("/");
  return arr[arr.length - 1];
};

/**
 * @description dialog提示
 */

export const dialog = (msg: string) => {
  ElMessageBox.alert(msg);
};
