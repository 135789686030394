
import { isPhone } from '@util';
import { onMounted, reactive, ref } from 'vue';

export default {
  setup() {
    const tabI = ref(0);
    const tabs = reactive([
      { label: "账号密码登录" },
      { label: "手机号登录" },
    ]);
    const iptAccList = reactive([
      {
        id: 0,
        label: "账户",
        icon: require("@login/account.png"),
        value: "0004",
      },
      {
        id: 1,
        label: "密码",
        icon: require("@login/pwd.png"),
        value: "123456",
        type: "password",
        rightIcon: require("@login/eyeOff.png"),
        tapRight: () => {
          const tmp = iptAccList.find((e) => e.id == 1)!;
          if (tmp.type == "text") {
            tmp.type = "password";
            tmp.rightIcon = require("@login/eyeOff.png");
          } else {
            tmp.type = "text";
            tmp.rightIcon = require("@login/eyeOn.png");
          }
        }
      },
    ]);
    const iptPhoList = reactive([
      {
        id: 2,
        label: "手机号",
        icon: require("@login/phone.png"),
        value: "",
      },
      {
        id: 3,
        label: "验证码",
        icon: require("@login/code.png"),
        tapRight: () => tapPhocode(),
      }
    ])
    const iptList = () => {
      return tabI.value == 0 ? iptAccList : tabI.value == 1 ? iptPhoList : [];
    }
    const tapPhocode = () => {
      const phone = iptPhoList.find((e) => e.id == 2)?.value?.trim();
      if (!isPhone(phone)) {
        // return tip("请输入正确手机号");
      }
      // 发送验证码接口
    }
    onMounted(() => { })
    return {
      tabI,
      tabs,
      title: window.proName,
      iptList,
    }
  }
}
