import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f852942"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "MyInput" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.ipt.icon)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: $props.ipt.icon,
          class: "icon"
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("input", _mergeProps({
      placeholder: $props.ipt.label
    }, $props.ipt, { class: "glFlex" }), null, 16, _hoisted_3),
    ($props.ipt.rightIcon)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($props.ipt.tapRight && $props.ipt.tapRight())),
          src: $props.ipt.rightIcon,
          class: "icon"
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true)
  ]))
}