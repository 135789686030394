/**
 * Created by zgl
 * @description:网络请求axios封装
 */

import axios from "axios";
// import { alert, hideLoading, loading } from "./MyUtil";

const instance = axios.create({
  baseURL: window.ng,
  // timeout: 30 * 1000,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    Accept: "application/json;charset=utf-8",
  },
});

instance.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem("token");
    if (!config.headers) {
      config.headers = {};
    }
    config.headers.token = token;
    console.log("request.url====>", (config.baseURL || "") + config.url);
    const param = config.params || config.data;
    console.log(config.method, param);
    return config;
  },
  (error) => {
    console.log("request.error====>", error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    console.log(
      `response====>${response.config.baseURL}${response.config.url}`,
      response.data
    );
    const token = response.headers.token;
    if (token) {
      localStorage.setItem("token", token);
      console.log("保存token====>", token);
    }
    return Promise.resolve(response);
  },
  (error) => {
    console.log(
      `response.error====>${error.config.baseURL}${error.config.url}`,
      error
    );
    const code = error.code;
    if (code == 401 || error.message.indexOf("401") > -1) {
      error.code = 401;
      error.message = "登录失效，请重新登录";
    } else if (error.message.indexOf("timeout") > -1) {
      error.code = 501;
      error.data = undefined;
    }
    return Promise.reject(error);
  }
);

// 网络请求公共参数
const publicParams = {
  // userId: '',
};

class WebService {
  request = ({
    url = "",
    data = <any>{},
    method = "POST",
    baseURL = window.ng,
    config = {},
  }) => {
    return new Promise<any>((resolve, reject) => {
      const meta = data.meta || { hideLoading: false };
      if (!meta.hideLoading) {
        // loading(meta.msg);
      }
      delete data.meta;
      method = method.toUpperCase();
      const requestParams = { ...publicParams, ...data };
      const param =
        method == "GET" ? { params: requestParams } : { data: requestParams };
      const baseUrl = window.debug ? baseURL : window.ng;
      instance({
        ...config,
        url,
        method,
        ...param,
        baseURL: baseUrl || window.ng,
      })
        .then((response) => {
          // hideLoading();
          if (response.status == 200) {
            const res = response.data.result || response.data;
            resolve(res);
          } else {
            reject(response.data);
          }
        })
        .catch((error) => {
          // hideLoading();
          this.handleError(error);
          reject(error);
        });
    });
  };

  isLoading = false;
  handleError = (error: any) => {
    console.log("handleError====>", error);
    if (error.code == 401) {
      // token过期时alert提示重新登录，无需跳转到登录界面
      if (!this.isLoading) {
        // alert("是否重新登录？", error.message || "登录失效",
        //   () => {
        //     this.login();
        //   },
        //   () => {
        //     this.isLoading = false;
        //   })
      }
    }
  };

  login = () => { };
}

export const service = instance;
export const net = new WebService().request;
