/**
 * author: zgl
 */

import { RouteRecordRaw } from "vue-router"

import MbHome from "./MbHome.vue"
import MbFaq from "./MbFaq.vue"

const basePath = "/mb/"
const baseName = "Mb"

export const RoutePath = {
  mbRoot: basePath,
  mbHome: basePath + "home",
  mbFaq: basePath + "faq"
}
export const RouteName = {
  mbHome: baseName + "Home",
  mbFaq: baseName + "Faq"
}

export const Routes: Array<RouteRecordRaw> = [
  {
    path: RoutePath.mbRoot,
    redirect: RoutePath.mbHome
  },
  {
    path: RoutePath.mbHome,
    name: RouteName.mbHome,
    component: MbHome,
    meta: {
      title: window.proName
    }
  },
  {
    path: RoutePath.mbFaq,
    name: RouteName.mbFaq,
    component: MbFaq,
    meta: {
      title: window.proName
    }
  }
]