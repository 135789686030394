import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw, useRouter } from 'vue-router'

import HomeView from '../views/HomeView.vue'
import {
  LoginRoute, LoginRoutePath, LoginRouteName,
  HomeRoute, HomeRoutePath, HomeRouteName, MbHomeRoutePath, MbHomeRouteName, MbHomeRoutes,
} from '@/views';

export const MyRouter = () => useRouter()

export const RoutePath = {
  ...LoginRoutePath,
  ...HomeRoutePath,
  ...MbHomeRoutePath,
}

export const RouteName = {
  ...LoginRouteName,
  ...HomeRouteName,
  ...MbHomeRouteName,
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: RoutePath.home,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  ...LoginRoute,
  ...HomeRoute,
  ...MbHomeRoutes,
]

const router = createRouter({
  history: createWebHashHistory(),
  // history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title as string || window.proName;
  next();
})

export default router
